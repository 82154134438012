import React, { useEffect, useState } from "react";
import { Avatar, Button, Text, Loader, Divider } from "@mantine/core";
import { doc, getDoc, updateDoc } from "firebase/firestore";
import { db } from "../../config/firebase-config";
import styled from "styled-components";

const ProfileContainer = styled.div`
  background: #f8fafc;
  border-radius: 16px;
  padding: 2rem;
  max-width: 600px;
  margin: 0 auto;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
`;

const ProfileHeader = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 2rem;

  .avatar {
    border: 4px solid #38bdf8;
  }

  h2 {
    margin: 1rem 0 0.5rem;
    color: #1e293b;
    font-size: 1.5rem;
  }

  p {
    color: #475569;
    font-size: 1rem;
  }
`;

const FieldGroup = styled.div`
  margin-bottom: 1.5rem;

  label {
    display: block;
    color: #475569;
    font-weight: 600;
    margin-bottom: 0.5rem;
    font-size: 0.9rem;
  }

  input {
    width: 100%;
    border-radius: 8px;
    padding: 0.75rem;
    border: 1px solid #d1d5db;
    background: #ffffff;
    color: #1e293b;
    font-size: 1rem;

    &:focus {
      outline: none;
      border-color: #38bdf8;
      box-shadow: 0 0 6px rgba(56, 189, 248, 0.3);
    }
  }

  input[readonly] {
    background: #f1f5f9;
    cursor: not-allowed;
  }
`;

const ProfileFooter = styled.div`
  text-align: center;
  margin-top: 2rem;
`;

const ErrorMessage = styled(Text)`
  color: #e63946;
  margin-top: 1rem;
`;

const SuccessMessage = styled(Text)`
  color: #2a9d8f;
  margin-top: 1rem;
`;

export const Profile = () => {
  const [userData, setUserData] = useState({
    name: "",
    email: "",
    phone: "",
    job: "",
    picture: "",
  });
  const [loading, setLoading] = useState(true);
  const [saving, setSaving] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  useEffect(() => {
    const fetchUserData = async () => {
      setLoading(true);
      try {
        const localAuth = JSON.parse(localStorage.getItem("auth"));
        const userId = localAuth?.userID;

        if (!userId) {
          setErrorMessage("Utilisateur non trouvé.");
          setLoading(false);
          return;
        }

        const userDocRef = doc(db, "users", userId);
        const userDoc = await getDoc(userDocRef);

        if (userDoc.exists()) {
          const profile = userDoc.data()?.userinfo?.profile || {};
          setUserData({
            name: profile.name || "",
            email: profile.email || "",
            phone: profile.phone || "",
            job: profile.job || "",
            picture: profile.picture || "https://via.placeholder.com/100",
          });
        } else {
          setErrorMessage("Aucun profil trouvé.");
        }
      } catch (error) {
        console.error("Erreur lors du chargement des données utilisateur :", error);
        setErrorMessage("Erreur lors du chargement des données utilisateur.");
      } finally {
        setLoading(false);
      }
    };

    fetchUserData();
  }, []);

  const handleSave = async () => {
    setSaving(true);
    try {
      const localAuth = JSON.parse(localStorage.getItem("auth"));
      const userId = localAuth?.userID;

      if (!userId) {
        setErrorMessage("Utilisateur non connecté.");
        setSaving(false);
        return;
      }

      // Mise à jour des données utilisateur dans Firestore
      const userDocRef = doc(db, "users", userId);
      await updateDoc(userDocRef, {
        "userinfo.profile.name": userData.name,
        "userinfo.profile.phone": userData.phone,
        "userinfo.profile.job": userData.job,
        "userinfo.profile.picture": userData.picture,
      });

      setSuccessMessage("Profil mis à jour avec succès !");
    } catch (error) {
      console.error("Erreur lors de la sauvegarde :", error);
      setErrorMessage(
        "Erreur lors de la sauvegarde des données. Vérifiez votre connexion."
      );
    } finally {
      setSaving(false);
      setTimeout(() => {
        setSuccessMessage("");
        setErrorMessage("");
      }, 3000);
    }
  };

  const handleInputChange = (field, value) => {
    setUserData((prev) => ({
      ...prev,
      [field]: value,
    }));
  };

  if (loading) {
    return (
      <div style={{ textAlign: "center", marginTop: "50px" }}>
        <Loader size="lg" />
        <Text>Chargement des données utilisateur...</Text>
      </div>
    );
  }

  return (
    <ProfileContainer>
      <ProfileHeader>
        <Avatar
          size={100}
          radius="xl"
          src={userData?.picture}
          alt="Avatar"
          className="avatar"
        />
        <h2>{userData?.name || "Nom inconnu"}</h2>
        <p>{userData?.email || "Email inconnu"}</p>
      </ProfileHeader>
      <Divider my="sm" />
      <FieldGroup>
        <label>Nom complet</label>
        <input
          type="text"
          value={userData.name}
          onChange={(e) => handleInputChange("name", e.currentTarget.value)}
        />
      </FieldGroup>
      <FieldGroup>
        <label>E-mail</label>
        <input type="email" value={userData.email} readOnly />
      </FieldGroup>
      <FieldGroup>
        <label>Téléphone</label>
        <input
          type="text"
          value={userData.phone}
          onChange={(e) => handleInputChange("phone", e.currentTarget.value)}
        />
      </FieldGroup>
      <FieldGroup>
        <label>Profession</label>
        <input
          type="text"
          value={userData.job}
          onChange={(e) => handleInputChange("job", e.currentTarget.value)}
        />
      </FieldGroup>
      <ProfileFooter>
        <Button
          onClick={handleSave}
          loading={saving}
          variant="gradient"
          gradient={{ from: "indigo", to: "cyan" }}
        >
          Sauvegarder
        </Button>
        {successMessage && <SuccessMessage>{successMessage}</SuccessMessage>}
        {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
      </ProfileFooter>
    </ProfileContainer>
  );
};

export default Profile;
