import React from "react";
import styled from "styled-components";
import { Tabs, rem } from "@mantine/core";
import {
  IconSettings,
  IconUser,
  IconBell,
  IconLock,
  IconCash,
} from "@tabler/icons-react";
import Layout from "../../component/Layout/Layout";

// Import des composants pour les onglets
import Profile from "./Profile";
import Preferences from "./Preferences";
import Notifications from "./Notifications";
import Security from "./Security";
import Subscription from "./Subscription";

const FullWidthBackground = styled.div`
  background: linear-gradient(135deg, #1e293b, #0f172a);
  color: #ffffff;
  min-height: 100vh;
  padding: 3rem 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const SettingsContainer = styled.div`
  background: #ffffff;
  color: #1e293b;
  border-radius: 16px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  max-width: 1100px; /* Largeur maximale ajustée */
  width: 90%;
  min-height: 80vh; /* Ajustement pour occuper plus d'espace */
  padding: 3rem;
  overflow: visible; /* Important pour afficher tout le contenu */

  @media (max-width: 768px) {
    padding: 2rem;
    width: 100%;
  }
`;

const StyledTabsList = styled(Tabs.List)`
  margin-bottom: 2rem;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 1rem; /* Espace entre les onglets */
  background: #e5e7eb;
  padding: 1rem;
  border-radius: 12px;

  @media (max-width: 768px) {
    justify-content: flex-start; /* Pour mobile, aligne les onglets à gauche */
  }
`;

const StyledTab = styled(Tabs.Tab)`
  font-size: 1rem;
  font-weight: 500;
  padding: 0.75rem 1.5rem; /* Augmenter la surface cliquable */
  border-radius: 8px;
  transition: background 0.3s ease;

  &[data-active] {
    background: #1e293b; /* Correspond à la couleur de fond */
    color: #ffffff;
  }

  &:hover {
    background: #374151; /* Légèrement plus clair pour l'effet de survol */
  }
`;

const StyledTabsPanel = styled(Tabs.Panel)`
  padding: 2rem;
  background: #f1f5f9; /* Couleur de fond pour le contenu */
  border-radius: 12px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin-top: 1rem;
`;

export const SettingsPage = () => {
  const iconStyle = { width: rem(16), height: rem(16) }; // Taille des icônes

  return (
    <Layout>
      <FullWidthBackground>
        <SettingsContainer>
          <Tabs defaultValue="profile" variant="pills">
            {/* Liste des onglets */}
            <StyledTabsList>
              <StyledTab value="profile" leftSection={<IconUser style={iconStyle} />}>
                Profil
              </StyledTab>
              <StyledTab
                value="preferences"
                leftSection={<IconSettings style={iconStyle} />}
              >
                Préférences
              </StyledTab>
              <StyledTab
                value="notifications"
                leftSection={<IconBell style={iconStyle} />}
              >
                Notifications
              </StyledTab>
              <StyledTab
                value="security"
                leftSection={<IconLock style={iconStyle} />}
              >
                Sécurité
              </StyledTab>
              <StyledTab
                value="subscription"
                leftSection={<IconCash style={iconStyle} />}
              >
                Abonnement
              </StyledTab>
            </StyledTabsList>

            {/* Contenu des onglets */}
            <StyledTabsPanel value="profile">
              <Profile />
            </StyledTabsPanel>
            <StyledTabsPanel value="preferences">
              <Preferences />
            </StyledTabsPanel>
            <StyledTabsPanel value="notifications">
              <Notifications />
            </StyledTabsPanel>
            <StyledTabsPanel value="security">
              <Security />
            </StyledTabsPanel>
            <StyledTabsPanel value="subscription">
              <Subscription />
            </StyledTabsPanel>
          </Tabs>
        </SettingsContainer>
      </FullWidthBackground>
    </Layout>
  );
};

export default SettingsPage;
