import React from "react";
import { Notification, Button } from "@mantine/core";

export const Subscription = () => {
  return (
    <div>
      <h3>Abonnement</h3>
      <Notification title="Plan Actuel" color="teal">
        Vous êtes actuellement abonné au plan Premium.
      </Notification>
      <Button variant="gradient" gradient={{ from: "indigo", to: "cyan" }} style={{ marginTop: "20px" }}>
        Gérer mon abonnement
      </Button>
    </div>
  );
};

export default Subscription;
