import React, { useState, useEffect } from "react";
import { Checkbox, Button, Text, Loader } from "@mantine/core";
import { doc, getDoc, updateDoc } from "firebase/firestore";
import { db } from "../../config/firebase-config";

export const Notifications = () => {
  const [notifications, setNotifications] = useState({
    emailAlerts: false,
    pushNotifications: false,
  });
  const [loading, setLoading] = useState(true);
  const [saving, setSaving] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const userID = JSON.parse(localStorage.getItem("auth"))?.userID;

  useEffect(() => {
    const fetchNotificationPreferences = async () => {
      setLoading(true);
      try {
        if (!userID) {
          console.error("Utilisateur non connecté ou non défini");
          setLoading(false);
          return;
        }

        const userDocRef = doc(db, "users", userID);
        const userDoc = await getDoc(userDocRef);

        if (userDoc.exists()) {
          const userData = userDoc.data();
          setNotifications({
            emailAlerts: userData.notifications?.emailAlerts || false,
            pushNotifications: userData.notifications?.pushNotifications || false,
          });
        } else {
          console.warn("Document utilisateur introuvable");
        }
      } catch (error) {
        console.error("Erreur lors du chargement des préférences de notifications :", error);
      } finally {
        setLoading(false);
      }
    };

    fetchNotificationPreferences();
  }, [userID]);

  const handleCheckboxChange = (field) => {
    setNotifications((prev) => ({
      ...prev,
      [field]: !prev[field],
    }));
  };

  const saveNotifications = async () => {
    setSaving(true);
    try {
      if (!userID) {
        console.error("Utilisateur non connecté ou non défini");
        setSaving(false);
        return;
      }

      const userDocRef = doc(db, "users", userID);
      await updateDoc(userDocRef, {
        notifications,
      });

      setSuccessMessage("Paramètres de notifications sauvegardés avec succès !");
    } catch (error) {
      console.error("Erreur lors de la sauvegarde des paramètres de notifications :", error);
    } finally {
      setSaving(false);
      setTimeout(() => {
        setSuccessMessage("");
      }, 3000);
    }
  };

  if (loading) {
    return (
      <div style={{ textAlign: "center", marginTop: "50px" }}>
        <Loader size="lg" />
        <Text>Chargement des paramètres de notifications...</Text>
      </div>
    );
  }

  return (
    <div>
      <h3>Notifications</h3>
      <Checkbox
        label="Recevoir les alertes par e-mail"
        checked={notifications.emailAlerts}
        onChange={() => handleCheckboxChange("emailAlerts")}
        style={{ marginBottom: "10px" }}
      />
      <Checkbox
        label="Recevoir les notifications push"
        checked={notifications.pushNotifications}
        onChange={() => handleCheckboxChange("pushNotifications")}
      />
      <Button
        style={{ marginTop: "20px" }}
        onClick={saveNotifications}
        loading={saving}
      >
        Sauvegarder
      </Button>
      {successMessage && (
        <Text
          style={{
            color: "#2a9d8f",
            marginTop: "20px",
            textAlign: "center",
          }}
        >
          {successMessage}
        </Text>
      )}
    </div>
  );
};

export default Notifications;
