import React, { useEffect } from "react";
import { Login } from "../../component/login/Login";
import { auth, provider, fbprovider, db } from "../../config/firebase-config";
import { signInWithPopup, getAdditionalUserInfo } from "firebase/auth";
import { useNavigate } from "react-router-dom";
import { doc, setDoc, serverTimestamp } from "firebase/firestore";
import { useGetUserInfo } from "../../hooks/useGetUserInfo";
import Register from "../../component/register/register";

export const Auth = ({ login = true }) => {
  const navigate = useNavigate();
  const { isAuth, emailVerified } = useGetUserInfo();

  // Fonction pour sauvegarder l'utilisateur dans Firestore
  const saveUserToFirestore = async (user, profile) => {
    if (!user?.uid) {
      console.error("User UID is missing. Skipping Firestore save.");
      return false;
    }

    try {
      const userDocRef = doc(db, "users", user.uid);
      await setDoc(userDocRef, {
        created: serverTimestamp(),
        uid: user.uid,
        online: true,
        userinfo: {
          name: user.displayName || "",
          cover: user.photoURL || "",
          email: user.email || "",
          phone: user.phoneNumber || "",
          metadata: {
            lastLoginAt: user.metadata.lastSignInTime,
            creationTime: user.metadata.creationTime,
          },
          profile: profile || {},
        },
        customization: {
          color: "#10325c",
          themecolor: "#0f6ce6",
          darkmode: false,
          widemode: false,
        },
      });
      console.log(`User ${user.uid} saved to Firestore.`);
      return true; // Enregistrement réussi
    } catch (error) {
      console.error("Error saving user to Firestore:", error);
      return false; // Enregistrement échoué
    }
  };

  // Connexion avec Google
  const signInWithGoogle = async () => {
    try {
      const results = await signInWithPopup(auth, provider);
      const isNewUser = getAdditionalUserInfo(results)?.isNewUser;
      const profile = getAdditionalUserInfo(results)?.profile;
      const user = results.user;

      if (isNewUser) {
        const saveSuccess = await saveUserToFirestore(user, profile);
        if (!saveSuccess) {
          throw new Error("Erreur lors de l'enregistrement de l'utilisateur.");
        }
      }

      localStorage.setItem("auth", JSON.stringify({
        userID: user.uid,
        name: user.displayName || "",
        profilePhoto: user.photoURL || "",
        isAuth: true,
        emailVerified: true,
      }));

      navigate(isNewUser ? "/preferences" : "/Home");
    } catch (error) {
      console.error("Erreur de connexion avec Google :", error);
    }
  };

  // Connexion avec Facebook
  const signInWithFacebook = async () => {
    try {
      const results = await signInWithPopup(auth, fbprovider);
      const isNewUser = getAdditionalUserInfo(results)?.isNewUser;
      const profile = getAdditionalUserInfo(results)?.profile;
      const user = results.user;

      if (isNewUser) {
        const saveSuccess = await saveUserToFirestore(user, profile);
        if (!saveSuccess) {
          throw new Error("Erreur lors de l'enregistrement de l'utilisateur.");
        }
      }

      localStorage.setItem("auth", JSON.stringify({
        userID: user.uid,
        name: user.displayName || "",
        profilePhoto: user.photoURL || "",
        isAuth: true,
        emailVerified: true,
      }));

      navigate(isNewUser ? "/preferences" : "/Home");
    } catch (error) {
      console.error("Erreur de connexion avec Facebook :", error);
    }
  };

  useEffect(() => {
    if (isAuth && emailVerified) {
      navigate("/Home");
    }
  }, [isAuth, emailVerified, navigate]);

  return login
    ? <Login signInWithGoogle={signInWithGoogle} signInWithFacebook={signInWithFacebook} />
    : <Register signInWithGoogle={signInWithGoogle} signInWithFacebook={signInWithFacebook} />;
};

export default Auth;
