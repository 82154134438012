import React, { useState, useEffect } from "react";
import { Button, Loader, Text } from "@mantine/core";
import { doc, getDoc, updateDoc } from "firebase/firestore";
import { db } from "../../config/firebase-config";

export const Preferences = () => {
  const [preferences, setPreferences] = useState([]);
  const [loading, setLoading] = useState(true);
  const [saving, setSaving] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");

  // Préférences disponibles par défaut
  const availablePreferences = [
    "Droit civil",
    "Droit pénal",
    "Droit commercial",
    "Droit administratif",
    "Droit international",
    "Droit fiscal",
  ];

  // Récupération de l'ID utilisateur depuis le localStorage
  const userID = JSON.parse(localStorage.getItem("auth"))?.userID;

  useEffect(() => {
    const fetchUserPreferences = async () => {
      setLoading(true);
      try {
        if (!userID) {
          console.error("Utilisateur non connecté ou non défini");
          setLoading(false);
          return;
        }

        const userDocRef = doc(db, "users", userID);
        const userDoc = await getDoc(userDocRef);

        if (userDoc.exists()) {
          const userData = userDoc.data();
          setPreferences(userData.preferences || []);
        } else {
          console.warn("Document utilisateur introuvable");
        }
      } catch (error) {
        console.error("Erreur lors du chargement des préférences :", error);
      } finally {
        setLoading(false);
      }
    };

    fetchUserPreferences();
  }, [userID]);

  const handlePreferenceChange = (pref) => {
    setPreferences((prev) =>
      prev.includes(pref)
        ? prev.filter((p) => p !== pref)
        : [...prev, pref]
    );
  };

  const savePreferences = async () => {
    setSaving(true);
    try {
      if (!userID) {
        console.error("Utilisateur non connecté ou non défini");
        setSaving(false);
        return;
      }

      const userDocRef = doc(db, "users", userID);
      await updateDoc(userDocRef, {
        preferences,
      });

      setSuccessMessage("Préférences sauvegardées avec succès !");
    } catch (error) {
      console.error("Erreur lors de la sauvegarde des préférences :", error);
    } finally {
      setSaving(false);
      setTimeout(() => {
        setSuccessMessage("");
      }, 3000); // Réinitialiser le message après 3 secondes
    }
  };

  if (loading) {
    return (
      <div style={{ textAlign: "center", marginTop: "50px" }}>
        <Loader size="lg" />
        <Text>Chargement des préférences...</Text>
      </div>
    );
  }

  return (
    <div>
      <h3>Préférences</h3>
      <div style={{ display: "flex", flexWrap: "wrap", gap: "10px" }}>
        {availablePreferences.map((pref) => (
          <div
            key={pref}
            style={{
              padding: "10px 15px",
              borderRadius: "8px",
              background: preferences.includes(pref) ? "#3b82f6" : "#e2e8f0",
              color: preferences.includes(pref) ? "#ffffff" : "#1e293b",
              cursor: "pointer",
            }}
            onClick={() => handlePreferenceChange(pref)}
          >
            {pref}
          </div>
        ))}
      </div>
      <Button
        style={{ marginTop: "20px" }}
        onClick={savePreferences}
        loading={saving}
      >
        Sauvegarder
      </Button>
      {successMessage && (
        <Text
          style={{
            color: "#2a9d8f",
            marginTop: "20px",
            textAlign: "center",
          }}
        >
          {successMessage}
        </Text>
      )}
    </div>
  );
};

export default Preferences;
