import React, { useState, useEffect } from "react";
import { Button, PasswordInput, Text } from "@mantine/core";
import { auth } from "../../config/firebase-config";
import {
  updatePassword,
  reauthenticateWithCredential,
  EmailAuthProvider,
} from "firebase/auth";

export const Security = () => {
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [isEmailPasswordUser, setIsEmailPasswordUser] = useState(false);

  useEffect(() => {
    const user = auth.currentUser;

    if (user?.providerData?.[0]?.providerId === "password") {
      // L'utilisateur utilise l'authentification par email/mot de passe
      setIsEmailPasswordUser(true);
    } else {
      setIsEmailPasswordUser(false);
    }
  }, []);

  const handleChangePassword = async () => {
    if (newPassword !== confirmPassword) {
      setErrorMessage("Les mots de passe ne correspondent pas.");
      return;
    }

    setLoading(true);
    setErrorMessage("");
    setSuccessMessage("");

    try {
      const user = auth.currentUser;
      if (!user) {
        throw new Error("Utilisateur non connecté.");
      }

      // Réauthentification
      const credentials = EmailAuthProvider.credential(user.email, currentPassword);
      await reauthenticateWithCredential(user, credentials);

      // Mise à jour du mot de passe
      await updatePassword(user, newPassword);
      setSuccessMessage("Mot de passe mis à jour avec succès !");
    } catch (error) {
      console.error("Erreur lors de la modification du mot de passe :", error);
      setErrorMessage(
        error.code === "auth/wrong-password"
          ? "Mot de passe actuel incorrect."
          : "Erreur lors de la modification du mot de passe. Veuillez réessayer."
      );
    } finally {
      setLoading(false);
      setTimeout(() => {
        setSuccessMessage("");
        setErrorMessage("");
      }, 3000);
    }
  };

  if (!isEmailPasswordUser) {
    return (
      <div style={{ maxWidth: "500px", margin: "0 auto", padding: "1rem" }}>
        <h3>Sécurité</h3>
        <Text style={{ textAlign: "center", color: "#475569", marginTop: "1rem" }}>
          La modification du mot de passe est uniquement disponible pour les comptes
          créés avec un email et un mot de passe.
        </Text>
      </div>
    );
  }

  return (
    <div style={{ maxWidth: "500px", margin: "0 auto", padding: "1rem" }}>
      <h3>Sécurité</h3>
      <PasswordInput
        label="Mot de passe actuel"
        placeholder="Entrez votre mot de passe actuel"
        value={currentPassword}
        onChange={(e) => setCurrentPassword(e.currentTarget.value)}
        style={{ marginBottom: "1rem" }}
      />
      <PasswordInput
        label="Nouveau mot de passe"
        placeholder="Entrez un nouveau mot de passe"
        value={newPassword}
        onChange={(e) => setNewPassword(e.currentTarget.value)}
        style={{ marginBottom: "1rem" }}
      />
      <PasswordInput
        label="Confirmer le nouveau mot de passe"
        placeholder="Confirmez votre nouveau mot de passe"
        value={confirmPassword}
        onChange={(e) => setConfirmPassword(e.currentTarget.value)}
        style={{ marginBottom: "1rem" }}
      />
      <Button
        onClick={handleChangePassword}
        loading={loading}
        variant="gradient"
        gradient={{ from: "indigo", to: "cyan" }}
        fullWidth
      >
        Modifier le mot de passe
      </Button>
      {successMessage && (
        <Text style={{ color: "#2a9d8f", marginTop: "1rem", textAlign: "center" }}>
          {successMessage}
        </Text>
      )}
      {errorMessage && (
        <Text style={{ color: "#e63946", marginTop: "1rem", textAlign: "center" }}>
          {errorMessage}
        </Text>
      )}
    </div>
  );
};

export default Security;
