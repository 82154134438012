import React from "react";
import { Card, Group, Text, ThemeIcon } from "@mantine/core";
import styled from "styled-components";
import { legalInformationData } from "../assets/data/data";
import { Helmet } from "react-helmet";
import image_home from '../assets/image_home.png'; 
// Wrapper principal pour la section des informations juridiques
const LegalInfoWrapper = styled.div`
  padding: 3rem 1rem;
  background: linear-gradient(135deg, #1e293b, #0f172a);
  color: #ffffff;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  overflow-x: auto;
  box-sizing: border-box;
`;

// Titre principal
const Title = styled.h3`
  font-size: 2.5rem; /* Titre plus grand */
  text-align: center;
  margin-bottom: 3rem;
  color: #38bdf8;
  font-weight: 700;

  @media (max-width: 768px) {
    font-size: 2rem;
  }
`;

// Conteneur des cartes, avec défilement horizontal
const SliderContainer = styled.div`
  display: flex;
  gap: 2rem; /* Espace entre les cartes */
  width: 100%;
  overflow-x: auto; /* Active le défilement horizontal */
  scroll-snap-type: x mandatory; /* Alignement fluide pour le défilement */
  padding: 2rem;
  padding-left: 4rem; /* Ajout d'un padding pour rendre visible la première carte */
  justify-content: flex-start;

  &::-webkit-scrollbar {
    display: none; /* Masquer la barre de défilement */
  }
`;

// Styles pour chaque carte individuelle
const StyledCard = styled(Card)`
  background: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(10px);
  color: #ffffff;
  border: 1px solid rgba(255, 255, 255, 0.2);
  flex: 0 0 auto;
  width: 95%;
  max-width: 400px;
  height: auto; /* Ajuste automatiquement la hauteur en fonction du contenu */
  scroll-snap-align: center;
  transition: transform 0.3s ease, box-shadow 0.3s ease;

  &:hover {
    transform: translateY(-10px);
    box-shadow: 0 12px 24px rgba(0, 0, 0, 0.4);
  }
`;

// Composant pour une carte individuelle
const CarouselCard = ({ data }) => {
  return (
    <StyledCard shadow="lg" radius="md" padding="lg">
      <Group position="apart" mt="lg">
        <Text weight={700} size="xl" color="#38bdf8">
          {data.title}
        </Text>
        <ThemeIcon
          radius="xl"
          size="xl"
          variant="gradient"
          gradient={{ from: "teal", to: "blue" }}
        >
          {data.icon}
        </ThemeIcon>
      </Group>
      <Text size="md" mt="sm" color="#e2e8f0">
        {data.description}
      </Text>
      {data.message && (
        <Text size="sm" mt="sm" color="#cbd5e1">
          {data.message}
        </Text>
      )}
    </StyledCard>
  );
};

// Composant principal pour la liste d'informations juridiques
export function LegalInformationList() {
  return (
    <>
      <Helmet>
        <title>Base juridique africaine et sénégalaise - Accès simplifié</title>
        <meta
          name="description"
          content="Explorez une base complète des lois, codes, décisions et doctrines juridiques pour l'Afrique et le Sénégal."
        />
        <meta
          name="keywords"
          content="droit africain, droit sénégalais, jurisprudence, OHADA, CEDEAO, codes sénégalais, doctrine juridique"
        />
        <meta
          property="og:title"
          content="Accédez à des informations juridiques essentielles pour le Sénégal et l'Afrique"
        />
        <meta
          property="og:description"
          content="Consultez les codes, décisions, doctrines et conventions collectives. Une solution pensée pour les besoins juridiques en Afrique et au Sénégal."
        />
        <meta property="og:image" content={image_home} />
        <meta property="og:url" content="https://legi.sn/portail" />
        <meta property="og:type" content="website" />
      </Helmet>
      <LegalInfoWrapper>
        <Title>
          Un accès simplifié à une diversité d'informations juridiques essentielles
        </Title>
        <SliderContainer>
          {legalInformationData.map((info, index) => (
            <CarouselCard key={index} data={info} />
          ))}
        </SliderContainer>
      </LegalInfoWrapper>
    </>
  );
}

export default LegalInformationList;
